@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  src: local('Gotham'), url('./assets/fonts/Gotham-Book.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: local('Gotham'), url('./assets/fonts/Gotham-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src: local('Gotham'), url('./assets/fonts/Gotham-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  src: local('Gotham'), url('./assets/fonts/Gotham-Medium.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

